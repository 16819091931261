import axios from 'axios';

const state = {
    list: null
};

const getters = {};

const actions = {

    async searchCampaign({rootGetters}, form) {
        return await axios.get('microsites/campaign-search', {params: form})
    },

    async getCampaigns({rootGetters}, form) {
        return await axios.get('campaign/list', {params: form});
    },

    async fetchLazadaData({rootGetters}, product_id){
        const headers = {
            Authorization: rootGetters['userToken'] ? `Bearer ${rootGetters['userToken']}` : '',
        }
        const response = await axios.get('campaign/lazada-product-details', {params: {product_id}, headers: headers})
        return response
    },

    async getCampaignImport({rootGetters}, form) {
        return await axios.get('campaign/list-imported', {params: form})
    },

    async uploadCampaignExcel({rootGetters}, form) {
        const headers = {
            headers: {
                'Content-Type': 'multipart/form-data',
            }
        }
        return await axios.post('campaign/upload-file', form, headers)
    },

    async createCampaign({rootGetters}, form) {
        const headers = {
            headers: {
                'Content-Type': 'multipart/form-data',
            }
        }

        return await axios.post('campaign/create', form, headers)
    },

    async createCampaignForAnotherDb({rootGetters}, form) {
        const headers = {
            headers: {
                'Content-Type': 'multipart/form-data',
            }
        }

        return await axios.post('campaign/save-to-another-db', form, headers)
    },

    async updateCampaign({rootGetters}, form) {
        const headers = {
            headers: {
                'Content-Type': 'multipart/form-data',
            }
        }

        return await axios.post('campaign/update', form, headers)
    },

    async detailCampaign({rootGetters}, form) {
        return await axios.get('campaign/detail', {params: form})
    },

    async runCampaign({rootGetters}, form) {
        const headers = {
            headers: {
                'Content-Type': 'application/json',
            }
        }

        return await axios.post('campaign/run', form, headers)
    },

    async stopCampaign({rootGetters}, form) {
        const headers = {
            headers: {
                'Content-Type': 'application/json',
            }
        }
        return await axios.post('campaign/stop', form, headers)
    },

    async deleteCampaign({rootGetters}, form) {
        const headers = {
            headers: {
                'Content-Type': 'application/json',
            }
        }
        return await axios.post('campaign/delete', form, headers)
    },
};

const mutations = {};

export default {
    state,
    getters,
    actions,
    mutations
};
